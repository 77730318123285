import { OrchardBrands } from './songwhipApi/types';
import { SHORT_HOST } from '~/config';

import {
  DEFAULT_DOMAIN,
  DEFAULT_DOMAIN_ORCHARD,
} from '~/app/components/CustomPageLinksDialog/constants';

/**
 * Legacy pages should use the `https://sng.to/pagePath` format whereas
 * Orchard created pages should use the new `https://artistPath.sng.to/albumOrTrackPath` format.
 *
 * The `pagePath` argument is of the form `/artistPath` for an Artist page
 * or `/artistPath/albumOrTrackPath for an Album or Track page.
 */
const toShortUrl = (pagePath: string, pageBrand?: OrchardBrands) => {
  if (!pageBrand) {
    return `https://${SHORT_HOST}${pagePath}`;
  }

  const parts = pagePath.split('/');
  const artistPath = parts[1];
  const albumOrTrackPath = parts.length > 2 ? `/${parts[2]}` : '';

  const defaultDomain =
    pageBrand === OrchardBrands.ORCHARD
      ? DEFAULT_DOMAIN_ORCHARD
      : DEFAULT_DOMAIN;

  return `https://${artistPath}.${defaultDomain}${albumOrTrackPath}`;
};

export default toShortUrl;
