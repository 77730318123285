import getServiceDisplayData from '~/app/lib/getServiceDisplayData';
import { ServiceTypes } from '~/types';

import { SelectOption } from '../Select2';
import { DefaultDomain } from '~/app/lib/songwhipApi/types';

export const DEFAULT_CHANNELS = [
  ServiceTypes.FACEBOOK,
  ServiceTypes.INSTAGRAM,
  ServiceTypes.SNAPCHAT,
  ServiceTypes.TIKTOK,
  ServiceTypes.YOU_TUBE,
  ServiceTypes.TWITTER,
];

export const DEFAULT_CHANNELS_OPTIONS = DEFAULT_CHANNELS.flatMap<SelectOption>(
  (channel) => {
    const channelData = getServiceDisplayData(channel);
    return channelData
      ? [{ id: channelData.name, text: channelData.name }]
      : [];
  }
);

export const DEFAULT_MEDIUM_OPTIONS: SelectOption[] = [
  { id: 'Paid', text: 'Paid' },
  { id: 'Owned', text: 'Owned' },
];

export const DEFAULT_DOMAIN_ID = -1;
export const DEFAULT_DOMAIN: DefaultDomain = 'sng.to';
export const DEFAULT_DOMAIN_ID_ORCHARD = -2;
export const DEFAULT_DOMAIN_ORCHARD: DefaultDomain = 'orch.stream';
